<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb>
        <BreadcrumbItem>订单查看</BreadcrumbItem>
      </Breadcrumb>
    </div>
    <section class="card__container">
      <div class="container__title">基本信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="订单号">
              {{ info.orderSn }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="换货机构">
              {{ info.orderCorporationName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="客户">
              {{ info.purchaserCorporationName }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="商家">
              {{ info.producerCorporationName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="申请时间">
              {{ info.createTime ? tsFormat(info.createTime) : "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单状态">
              {{ info.nodeStatus }}
            </a-form-item>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <a-form-item label="原订单号">
              <a>
                <span @click="onCheckPurchaseOrder(info.orderPurchaseSn)">
                  {{ info.orderPurchaseSn }}
                </span>
              </a>
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="订单备注">
              {{ info.orderMemo }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section
      class="card__container"
      v-if="
        info.nodeStatus !== '商家审核中' &&
          info.nodeStatus !== '已取消' &&
          info.nodeStatus !== '已退回' &&
          info.nodeStatus !== '签约中'
      "
    >
      <div class="container__title">
        物流信息
        <span v-if="sellBillNum + customBillNum > 0">
          （{{ sellBillNum + customBillNum }}）条
        </span>
      </div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8" v-if="customBillNum > 0">
            <a-form-item :label="`客户 —> 商家（${customBillNum}）条`">
              <a @click="onCheckDetail('custom')">查看详情</a>
            </a-form-item>
          </a-col>
          <a-col :span="8" v-if="sellBillNum > 0">
            <a-form-item :label="`商家 —> 客户（${sellBillNum}）条`">
              <a @click="onCheckDetail('seller')">查看详情</a>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">商家收发货信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="收发货人姓名">
              {{ info.proName || "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收发货人电话">
              {{ info.proTel || "-" }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收发货地址">
              {{ info.proAddress || "-" }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">客户收发货信息</div>
      <a-form class="advanced-search-form">
        <a-row>
          <a-col :span="8">
            <a-form-item label="收发货人姓名">
              {{ info.purName }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收发货人电话">
              {{ info.purTel }}
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="收发货地址">
              {{ info.purAddress }}
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </section>
    <section class="card__container">
      <div class="container__title">商品信息</div>
      <a-table
        class="commodity__table"
        :data-source="commodityData"
        :columns="commodityColumns"
        :loading="loading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="goodsSn" slot-scope="text, record">
          <span v-if="record.skuCode === '合计：'"></span>
          <span v-else>{{ text ? text : "-" }}</span>
        </template>
      </a-table>
    </section>
    <section
      class="card__container"
      v-if="
        info.nodeStatus !== '商家审核中' &&
          info.nodeStatus !== '已取消' &&
          info.nodeStatus !== '已退回'
      "
    >
      <div class="container__title">合同信息</div>
      <a-table
        class="contract__table"
        :columns="contractColumns"
        :data-source="info.contracts"
        :loading="loading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="signOne" slot-scope="text, record">
          {{ record.partyFirst }}（{{ record.partyFirstSignStatus }}）
        </template>
        <template slot="signTwo" slot-scope="text, record">
          {{ record.partySecond }}（{{ record.partySecondSignStatus }}）
        </template>
        <template slot="contract" slot-scope="text, record">
          {{ record.contractFile ? record.contractFile.fileName : "" }}（{{
            record.contractStatus
          }}）
        </template>
        <template slot="signTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a
            :href="imageBaseUrl + record.contractFile.fileUrl"
            v-if="record.contractFile"
            target="_blank"
          >
            预览
          </a>
        </template>
      </a-table>
    </section>
    <section class="card__container low__bottom">
      <div class="container__title space__between">
        流程日志
        <a @click="onShowProcessImg" class="process_img">点击查看流程图</a>
        <div v-viewer class="images">
          <img
            v-if="
              info.producerCorporationDistributorTypeValue === '货栈' ||
                info.producerCorporationDistributorTypeValue === '服务中心'
            "
            src="../../../assets/imgs/process/endService.png"
            :data-source="
              require('../../../assets/imgs/process/endService.png')
            "
            alt="流程图"
          />
          <img
            v-if="
              info.purchaserCorporationDistributorTypeValue === '渠道终端' &&
                info.producerCorporationDistributorTypeValue === '原厂库'
            "
            src="../../../assets/imgs/process/endFactory.png"
            :data-source="
              require('../../../assets/imgs/process/endFactory.png')
            "
            alt="流程图"
          />
          <img
            v-if="
              info.purchaserCorporationDistributorTypeValue === '服务中心' &&
                info.producerCorporationDistributorTypeValue === '原厂库'
            "
            src="../../../assets/imgs/process/changeService.png"
            :data-source="
              require('../../../assets/imgs/process/changeService.png')
            "
            alt="流程图"
          />
        </div>
      </div>
      <a-table
        class="log__table"
        :columns="logColumns"
        :data-source="logData"
        :loading="logLoading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="createTime" slot-scope="text">
          {{ text ? tsFormat(text) : "-" }}
        </template>
        <template slot="reason" slot-scope="text">
          {{ text ? text : "-" }}
        </template>
      </a-table>
    </section>
    <div class="top__back__container" v-if="showTop" @click="onScrollTop">
      <img src="../../../assets/order/icon-top.png" alt="icon" />
      回到顶部
    </div>
  </a-layout>
</template>

<script>
import BreadcrumbItem from "@/components/BreadcrumbItem";
import Breadcrumb from "@/components/Breadcrumb";
import { tsFormat } from "@/components/DateUtils";
import {
  checkDeliverBill,
  fetchOrderDetail,
  fetchOrderLog
} from "@/services/OrderManageService";
import { imageBaseUrl } from "@/services/HttpService";

const commodityColumns = [
  {
    title: "商品编号",
    dataIndex: "skuCode",
    width: "15%"
  },
  {
    title: "商品名称",
    dataIndex: "skuName",
    width: "15%"
  },
  {
    title: "出厂单价（元）",
    dataIndex: "skuFactoryPrice",
    width: "15%"
  },
  {
    title: "采购单价（元）",
    dataIndex: "skuPurchasePrice",
    width: "15%"
  },
  {
    title: "换货数量",
    dataIndex: "purchaseQuantity",
    width: "10%"
  },
  {
    title: "出厂总价（元）",
    dataIndex: "skuFactoryTotalPrice",
    width: "15%"
  },
  {
    title: "采购总价（元）",
    dataIndex: "skuPurchaseTotalPrice",
    width: "15%"
  }
];
const logColumns = [
  {
    title: "执行人",
    dataIndex: "realName",
    width: "15%"
  },
  {
    title: "机构名称",
    dataIndex: "corporationName",
    width: "15%"
  },
  {
    title: "渠道类型",
    dataIndex: "distributorType",
    width: "15%"
  },
  {
    title: "操作时间",
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
    width: "20%"
  },
  {
    title: "执行动作",
    dataIndex: "operation",
    width: "5%"
  },
  {
    title: "审核意见",
    dataIndex: "reason",
    scopedSlots: { customRender: "reason" },
    width: "30%"
  }
];
const contractColumns = [
  {
    title: "签署方1（签署状态）",
    dataIndex: "signOne",
    scopedSlots: { customRender: "signOne" },
    width: "20%"
  },
  {
    title: "签署方2（签署状态）",
    dataIndex: "signTwo",
    scopedSlots: { customRender: "signTwo" },
    width: "20%"
  },
  {
    title: "合同名称（合同状态）",
    dataIndex: "contract",
    scopedSlots: { customRender: "contract" },
    width: "20%"
  },
  {
    title: "签订时间",
    dataIndex: "signTime",
    scopedSlots: { customRender: "signTime" },
    width: "20%"
  },
  {
    title: "操作",
    dataIndex: "action",
    scopedSlots: { customRender: "action" },
    width: "20%"
  }
];

export default {
  name: "ChangeOrderDetailOperation",
  components: { Breadcrumb, BreadcrumbItem },
  data() {
    return {
      orderSn: this.$route.params.orderSn,
      info: {},
      tsFormat,
      commodityData: [],
      commodityColumns,
      loading: false,
      logColumns,
      logData: [],
      logLoading: false,
      showTop: false,
      contractColumns,
      imageBaseUrl,
      sellBillNum: 0,
      customBillNum: 0
    };
  },
  mounted() {
    this.loadDetail();
    this.loadLogList();
    this.loadCustomDeliverBill();
    this.loadSellerDeliverBill();
    const that = this;
    window.onscroll = function() {
      const scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      const windowHeight =
        document.documentElement.clientHeight || document.body.clientHeight;
      const scrollHeight =
        document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + windowHeight >= scrollHeight) {
        that.showTop = true;
      } else {
        that.showTop = false;
      }
    };
  },
  methods: {
    // 加载流程日志
    loadLogList() {
      this.logLoading = true;
      fetchOrderLog(this.orderSn, "").then(resp => {
        this.logLoading = false;
        if (resp.data.code === "SUCCESS") {
          this.logData = resp.data.data;
        }
      });
    },
    // 加载详情
    loadDetail() {
      this.loading = true;
      const params = {
        orderSn: this.orderSn,
        type: "receive"
      };
      fetchOrderDetail(params).then(resp => {
        this.loading = false;
        if (resp.data.code === "SUCCESS") {
          this.info = resp.data.data;
          if (
            this.info.instantOrderSkus &&
            this.info.instantOrderSkus.length > 0
          ) {
            this.commodityData = this.info.instantOrderSkus;
            this.calculate(this.commodityData);
          }
        }
      });
    },
    // 回到顶部
    onScrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    // 查看流程图
    onShowProcessImg() {
      const viewer = this.$el.querySelector(".images").$viewer;
      viewer.show();
    },
    // 计算
    calculate(list) {
      let quantity = 0;
      let factoryPrice = 0;
      let purchasePrice = 0;
      list.forEach(item => {
        quantity = quantity + item.purchaseQuantity;
        factoryPrice = factoryPrice + item.skuFactoryTotalPrice;
        purchasePrice = purchasePrice + item.skuPurchaseTotalPrice;
      });
      const params = {
        skuCode: "合计",
        purchaseQuantity: quantity,
        skuFactoryTotalPrice: factoryPrice.toFixed(2),
        skuPurchaseTotalPrice: purchasePrice.toFixed(2)
      };
      list.push(params);
    },
    // 查询客户的发货单
    loadCustomDeliverBill() {
      checkDeliverBill(this.orderSn, "").then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data && data.length > 0) {
            this.customBillNum = data.length;
          }
        }
      });
    },
    // 查询商家的发货单
    loadSellerDeliverBill() {
      checkDeliverBill(this.orderSn, "producer").then(resp => {
        if (resp.data.code === "SUCCESS") {
          const data = resp.data.data;
          if (data && data.length > 0) {
            this.sellBillNum = data.length;
          }
        }
      });
    },
    // 查看收发货详情
    onCheckDetail(type) {
      this.$router.push({
        name: "DeliverReceiveDetail",
        params: {
          orderSn: this.orderSn,
          type: type,
          producerCorporationName: this.info.producerCorporationName,
          purchaserCorporationName: this.info.purchaserCorporationName
        }
      });
    },
    // 查看采购订单
    onCheckPurchaseOrder(orderSn) {
      const str = orderSn.substring(0, 2);
      if (str === "YQ") {
        const routeData = this.$router.resolve({
          name: "ForwardPurchaseSubOrder",
          params: { orderSn: orderSn, status: "payment-approval" }
        });
        window.open(routeData.href, "_blank");
      } else {
        const routeData = this.$router.resolve({
          name: "PurchaseOrderDetailFactory",
          params: { orderSn: orderSn, type: "detail" }
        });
        window.open(routeData.href, "_blank");
      }
    }
  }
};
</script>

<style scoped>
/deep/ .ant-form-item {
  margin: 10px 40px 0 0;
}

/deep/ .ant-form-item .ant-form-item-label {
  min-width: 85px !important;
  text-align: left !important;
}

/deep/ .ant-form-item-label > label {
  color: rgba(102, 102, 102, 0.8);
  font-size: 14px;
}

.commodity__table,
.log__table {
  margin-top: 20px;
}

.low__bottom {
  margin-bottom: 30px;
}

.top__back__container {
  border-radius: 4px 0px 0px 4px;
  background-color: rgba(16, 16, 16, 0.4);
  color: white;
  box-sizing: border-box;
  padding: 8px 10px 8px 15px;
  position: fixed;
  right: 0;
  bottom: 200px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
}

.top__back__container img {
  width: 24px;
  height: 24px;
}

.contract__table {
  margin-top: 20px;
}

.space__between {
  display: flex;
  justify-content: space-between;
}

.images img {
  display: none;
}

.process_img {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  font-weight: unset;
}
</style>
